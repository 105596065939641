import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'quill-image-render',
  templateUrl: './image-render.component.html',
  styleUrls: ['./image-render.component.scss'],
})
export class ImageRenderComponent implements OnInit {
  @Input()
  imgSrc?: string;

  @Input()
  imgSrcFallback?: string;

  @Input()
  additionalCSS?: string;

  @Input()
  alt?: string;

  constructor() {}

  ngOnInit(): void {}
}
