import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, mergeMap, Observable } from 'rxjs';
import { SimpleInfo } from '../core/dtos/simple-info';
import { jwtDecode } from 'jwt-decode';

export class AuthUser {
  constructor(
    public userId: string,
    public title: string,
    public givenName: string,
    public familyName: string,
    public email: string,
    public roles: string[],
    public hasAccess: boolean,
    public quillRole: SimpleInfo | null
  ) {}

  public get hasAdminAccess() {
    return this.quillRole !== null && this.quillRole.id === 3;
  }

  public get hasAuthorAccess() {
    return this.quillRole !== null && this.quillRole.id >= 2;
  }

  public get fullName() {
    return `${this.title} ${this.givenName} ${this.familyName}`.trim();
  }

  public get displayName() {
    return `${this.givenName} ${this.familyName}`.trim();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  setHasAccessToken(hasValidAccessToken: boolean) {
    this.hasValidAccessTokenSubject.next(hasValidAccessToken);
  }
  private userSubject = new BehaviorSubject<AuthUser | null>(null);
  private hasValidAccessTokenSubject = new BehaviorSubject<boolean>(false);

  public get hasValidAccessToken$() {
    return this.hasValidAccessTokenSubject.asObservable();
  }
  public get user$() {
    return this.userSubject.asObservable();
  }

  constructor(private oauthService: OAuthService) {}

  public setUser(data: {
    accessToken: string | null;
    hasAccess: boolean;
    role: SimpleInfo | null;
  }) {
    const { accessToken, hasAccess, role } = data;

    if (!accessToken) {
      this.userSubject.next(null);
      return;
    }

    // decode the access token to read the payload details by parsing the base64 encoded string
    const decodedToken = jwtDecode<any>(accessToken);

    const user = new AuthUser(
      decodedToken.sub,
      decodedToken.title,
      decodedToken.given_name,
      decodedToken.family_name,
      decodedToken.email,
      // this could either be a single string or an array of strings, so we need to normalize it
      this.checkRole(decodedToken),
      hasAccess,
      role
    );
    this.userSubject.next(user);
  }

  checkRole(token: any): [any] | [] {
    if (!token.role) {
      return [];
    }
    return Array.isArray(token.role) ? token.role : [token.role];
  }

  reloadUser(): Observable<AuthUser | null> {
    // convert promise to observable
    return from(this.oauthService.silentRefresh()).pipe(
      mergeMap((_) => this.user$)
    );
  }
}
