// TODO - Update this file with the correct values for the dev server
export const environment = {
  production: true,
  apiUrl: 'https://api-quill.preview-site.website',
  publicUrl: 'https://quill.preview-site.website',
  clientSettings: {
    authority: 'https://auth.sync.preview-site.website',
    client_id: 'quill',
    scope: 'openid profile email quill',
  },
};
